<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="검색"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      item-key="stock_code"
      :headers="searchListheaders"
      :items="searchList"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
      :loading="loadTable"
      loading-text="검색 중입니다. 잠시만 기다려 주세요."
    > 
      <template #item.stock_name="{ item }">
        <a target="_blank" :href="item.url">
          {{ item.stock_name }}
        </a>
      </template>

      <template v-slot:item.action="{ item }">
          <v-checkbox
              @click="put_ports(item)"
              v-model="item.action"
          >
          </v-checkbox>
      </template> 
    </v-data-table>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    props: [ 'searchListheaders', 'searchList', 'loadTable', 'disabled'],
    data () {
      return {
          search: '',
          selectedCodes: [],
          selectedNames: [],
      }
    },

    created() {
      this.selectedCodes = [];
      this.selectedNames = [];
      this.setPortCodeName({'port_codes': null, 'port_names': null});
    },

    methods: {
      ...mapMutations(['setPortCodeName']),
  
      put_ports(item) {
        if (item.action) {
            this.selectedCodes.push(item.stock_code);
            this.selectedNames.push(item.stock_name);
        } else {
            this.selectedCodes.pop(item.stock_code);
            this.selectedNames.push(item.stock_name);
        }
        this.setPortCodeName({'port_codes': this.selectedCodes, 'port_names': this.selectedNames});
      },
    },

  }
</script>