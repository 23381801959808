<template>
    <v-card>
        <v-card-title>
        <span class="text-h5">나의 포트폴리오에 추가</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
            <v-col cols="12">               
              <slot name="title"> {{ title }} </slot>
            </v-col>
            <v-col cols="12">
              <span v-for="(item, index) in port_names" :key="index">  
                  <v-chip class="ma-2" small> {{ item }} </v-chip>    
              </span>
            </v-col>                                                    
            </v-row>
            <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">               
              <slot name="port_amount"> {{ port_amount }} </slot>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">               
              <slot name="port_fee"> {{ port_fee }} </slot>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">               
              <slot name="port_broker"> {{ port_broker }} </slot>
            </v-col>
            </v-row>            
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer><v-spacer></v-spacer>
          <v-btn depressed outlined @click="$emit('hide')">닫기</v-btn>
          <v-btn color="primary" @click="$emit('submit')">저장</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
    name: "PortDialog",
    computed: {
        ...mapState(['port_names']) 
    },
}
</script>

<style>
.quant-rule-save{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 140px;
}
</style>
