<template>
<div class="quant-search">
  
  <span class="text-h5 text--primary d-flex font-weight-bold">   
    <v-icon left @click="$router.go(-1)"> mdi-arrow-left </v-icon> 
    {{title}} 종목 리스트
  </span>

  <!-- 상단 버튼 -->
  <v-container fluid>
    <v-row
      no-gutters
    >
      <v-col  
        v-for="j in rows"
        :key="j"
        :class="rows === 1 ? 'd-flex justify-end' : 'd-flex justify-space-between'"
      >
        <v-btn     
          v-for="k in 2/rows"
          :key="k"
          v-show="btnShow[j*k-1]"
          text
          color="primary"
          small 
          class="font-weight-bold"
          @click="btnFnc(j*k-1)"
        >
          <v-icon left> 
            {{ icon[j*k-1] }} 
          </v-icon>
          {{ btnName[j*k-1] }}
        </v-btn>        
      </v-col>
    </v-row>
  </v-container>

  <v-row
    align="center"
    justify="end"
  >

    <v-dialog 
        v-model="portDialogShow"
        max-width="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <PortDialog
          @hide="portDialogShow = false"
          @submit="put_my_ports()"
        >    
          <template v-slot:title>
            <v-text-field 
              v-model="port_title"
              label="제목*" 
              hint="포트폴리오 제목을 수정할 수 있습니다." 
              persistent-hint 
            > 
              {{ port_title }} 
            </v-text-field>          
          </template>      
          <template v-slot:port_amount>
            <v-text-field
              v-model="portAmount"
              label="투자금*"
              hint="투자금을 입력하세요."
              persistent-hint
              prefix="만원"
            />
          </template>      
          <template v-slot:port_fee>  
            <v-text-field
              v-model="portFeeRate"
              label="수수료(%)"
              hint="증권사 거래수수료를 입력하세요."
              persistent-hint
              required
            />
          </template>      
          <template v-slot:port_broker>
            <v-text-field
              v-model="portBroker"
              label="증권사"
              hint="거래 증권사를 입력하세요."
              persistent-hint
            />
          </template>  
        </PortDialog>
    </v-dialog>
    <v-dialog 
        v-model="rebalanceDialogShow"
        max-width="1000"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >리밸런싱 종목
        </v-toolbar>
        <v-data-table
          :headers="rebalanceHeaders"
          :items="rebalances"   
          hide-default-header       
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="rebalanceDialogShow = false"
          >닫기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <br>
  
  <v-alert
    v-if="alertShow"
    dense
    outlined
    type="error"
  >
    {{ alertMsg }}
  </v-alert>
  
  <!-- Progress Line -->
  <v-container v-if="loadPort" style="height: 200px;" >
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col
        class="text-subtitle-1 text-center"
        cols="12"
      >
        포트폴리오 구성 중입니다.
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>

  <QuantTable
    :searchListheaders="searchListheaders"
    :searchList="searchList"
    :loadTable="loadTable"
    :disabled="disabled"
    v-if="searchResultShow"
  />
  
  <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        닫기
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import QuantTable from '../components/QuantTable.vue';
  // import RuleDialog from '../components/RuleDialog.vue';
  import PortDialog from '../components/PortDialog.vue';
  
  export default {
    name: "QuantSearchV02",
    // components: { QuantTable, RuleDialog, PortDialog },
    components: { QuantTable, PortDialog },
    data () {
      return {
        btnName: [
          '나의 포트폴리오에 추가',
          '나의 포트폴리오 리밸런싱',
        ],
        icon: [
          'mdi-plus',
          'mdi-chart-arc',
        ],

        btnShow: [true, false],
        searchFilterShow: true,
        disabled: true,
        loadPort: false,

        rule_title: null,
        port_title: null,
        searchListheaders: [],
        searchList: [],
        quantList: [],

        portAmount: null,
        portFeeRate: 0.20,
        portBroker: null,

        loadTable: false,        
        searchResultShow: false,
        ruleDialogShow: false,
        portDialogShow: false,
        addBtnShow: false,
        addPortShow: false,
        filterTableShow: false,
        rebalanceDialogShow: false,
        rebalncePortShow: false,

        buy_names: null,
        sell_names: null,
        hold_names: null,

        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,

        alertShow: false,
        alertMsg: null,

        rebalanceHeaders: [
          { text: '구분', value: 'category', align: 'start'},
          { text: '종목 리스트', value: 'stock_name', align: 'start'},
        ],
        rebalances: [],
      }
    },    

    computed: {
      ...mapState([ 'who', 'isLogin', 'debug', 'user', 'market', 'market_cap', 'sector', 'title', 'filters', 
        'quant_id', 'rules', 'route', 'port_id', 'port_codes' ]),
      
      rows() {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 2 : 1
      },  
    },

    created() {
      if (this.isLogin) this.getSearchResult();
      else this.$router.push('/');   
    },

    methods: {     
      ...mapMutations(['setMarket', 'setRule']),

      btnFnc(idx) {
        if ( idx === 0 ) this.checkPortData();
        else if ( idx === 1 ) this.rebalancePort();
      },

      checkPortData() {
        console.log('+++++++++++++', this.port_codes)
        if (this.port_codes == null) {
          this.snackbar = true;
          this.snackColor = 'warning';
          this.snackText = '포토폴리오에 추가할 종목을 먼저 선택하세요.';        
        } else {
          this.portDialogShow = true;
        }
      },

      getSearchResult() {            
        if (this.route === 'backtest') {
          this.btnShow[1] = false;
          this.btnShow[0] = true;
          this.disabled = false;
        }
        else if (this.route === 'rebalance') {
          this.btnShow[1] = true;
          this.disabled = false;
        } else {
          this.btnShow[0] = true;
          this.disabled = false;        
        }        

        this.loadTable = true;
        this.searchFilterShow = false;        
        this.searchResultShow = true;
        this.searchListheaders.splice(0, this.searchListheaders.length);    
        this.searchList.splice(0, this.searchList.length);   
        this.port_title = this.title;
        var vm = this;

        // header
        this.searchListheaders.push({text: '순위', value: 'rank'});
        this.searchListheaders.push({text: '종목코드', value: 'stock_code'});
        this.searchListheaders.push({text: '종목명', value: 'stock_name', width: '200px'});
        // this.searchListheaders.push({text: '시장', value: 'stock_market'});
        // this.searchListheaders.push({text: '시총(억원)', value: 'stock_market_cap', align: 'end'});
        // this.searchListheaders.push({text: '업종', value: 'stock_sector'});
        // this.searchListheaders.push({text: '지정내역', value: 'stock_status'});
        for(var k in this.rules) {
            this.searchListheaders.push({text: this.rules[k]['text'], value: this.rules[k]['value'], align: 'end'});
        }
        this.searchListheaders.push({text: '선택', value: 'action'});
        this.queryData = {'user': this.user, 'market': this.market, 'market_cap': this.market_cap, 'sectors': this.sector, 
          'filters': this.filters, 'rules': this.rules};     
           
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};            
        axios.post('/api/quant/list/', this.queryData, {headers})
            .then(function(res) {
                vm.searchList = res.data;
                vm.loadTable = false;             
            })
            .catch(function (err) {
                vm.loadTable = false;    
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;            
            });                
        },

        put_my_quant_rules () {
          var saveQuantRules = {}
          saveQuantRules = { 'user': this.user, 'title': this.rule_title, 'market': this.market, 'market_cap': this.market_cap, 
            'sector': this.sector, 'filter_items': this.filters, 'search_items': this.rules };
          var vm = this;
          
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
          axios.post('/api/quant/save/', saveQuantRules, {headers})
            .then(function(res) {
                vm.$router.push('/rule');
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;
            });
        },

        put_my_ports() {
          if (!this.portAmount) {
            this.snackbar = true;
            this.snackColor = 'warning';
            this.snackText = "예상 투자금을 입력하세요.";
          } else {
            this.portDialogShow = false;
            this.loadPort = true;
            var savePortRules = { 'user': this.user, 'title': this.port_title, 'port_codes': this.port_codes, 'quant_id': this.quant_id,
              'who': this.who, 'port_amount': this.portAmount*10000, 'port_fee_rate': this.portFeeRate, 'port_broker': this.portBroker, 'class': 'static' };
            var vm = this;
            
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};   
            axios.post('/api/quant/port/save/domestic/', savePortRules, {headers})
              .then(function(res) {
                  vm.loadPort = false;
                  vm.$router.push('/domestic/port');
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;
              });
          }
        },

        rebalancePort() {
          if (this.port_codes == null) {
            this.snackbar = true;
            this.snackColor = 'warning';
            this.snackText = "리밸랜싱할 종목을 먼저 선택하세요.";       
          } else {            
            var vm = this;
            this.rebalanceDialogShow = true;
            var rebalance = { 'user': this.user, 'port_codes': this.port_codes, 'quant_id': this.quant_id };
      
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
            axios.post(`/api/quant/port/rebalance/domestic/${this.port_id}/`, rebalance, {headers})
              .then(function(res) {
                vm.rebalances = res.data;  
                // vm.$router.push('/port');
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;
              });
          }
        },

    },

  }
</script>

<style>
  .quant-search{
    padding: 10px 10px;
    margin-top: 10px;
  }

</style>
